import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { DispatchContext, StateContext } from '../App'
import { useHistory } from 'react-router-dom'
import { routes } from '../Routes'
import FormInput from '../elements/FormInput'

function YourDetails() {
    const dispatch = useContext(DispatchContext)
    const { data } = useContext(StateContext)
    const history = useHistory()

    const { errors, handleSubmit, register } = useForm({
        defaultValues: data,
        mode: 'onBlur',
    })

    const save = async (formData) => {
        dispatch({
            type: 'setPerson',
            payload: formData.person,
        })
        history.push(routes.agreeTerms.path)
    }

    return (
        <form onSubmit={handleSubmit(save)} className="your-details-form">
            <div className="row main-container">
                <div className="col-sm-12">
                    <h3 className="content-title">Tell us about you</h3>
                    <div className="form-row">
                        <div className="col">
                            <FormInput
                                type="text"
                                className="form-control"
                                label="First name"
                                name="person.firstName"
                                errors={errors}
                                inputRef={register({ required: true })}
                            />
                        </div>
                        <div className="col">
                            <FormInput
                                type="text"
                                className="form-control"
                                label="Last name"
                                name="person.lastName"
                                errors={errors}
                                inputRef={register({ required: true })}
                            />
                        </div>
                    </div>
                    <div className="form-row mt-3">
                        <div className="col">
                            <FormInput
                                type="email"
                                className="form-control"
                                label="Email address"
                                name="person.email"
                                errors={errors}
                                inputRef={register({
                                    required: true,
                                    pattern: {
                                        // Regex borrowed from here: https://emailregex.com/
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message:
                                            'Please enter a valid email address',
                                    },
                                })}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row button-container">
                <div className="col-sm-12 text-center">
                    <button type="submit" className="btn btn-primary">
                        Next
                    </button>
                </div>
            </div>
        </form>
    )
}

export default YourDetails
