import React, { useContext } from 'react'
import { StateContext, DispatchContext } from '../App'
import { Link, useHistory } from 'react-router-dom'
import { routes } from '../Routes'

function VehicleDetails() {
    const { data } = useContext(StateContext)
    const dispatch = useContext(DispatchContext)
    const history = useHistory()
    const reset = () => {
        dispatch({ type: 'resetVehicle' })
        history.push(routes.vehicleLookup.path)
    }
    return (
        <div>
            <div className="main-container">
                <div>
                    <h3 className="content-title">Is this your car?</h3>
                    <div
                        id="numberplate-confirmation"
                        className="card aos-init aos-animate"
                        data-aos="zoom-in"
                        data-aos-duration="500"
                    >
                        <div className="card-body">
                            <div className="text-center">
                                <h3 className="number-plate">
                                    {data.vehicle.registration}
                                </h3>
                                <p className="find-car-details mb-0">
                                    {(
                                        data.vehicle.colour +
                                        ' ' +
                                        data.vehicle.manufacturer +
                                        ' ' +
                                        data.vehicle.model
                                    ).trim()}
                                    , {data.vehicle.engineCapacity}{' '}
                                    {data.vehicle.fuelType}
                                </p>
                            </div>
                        </div>
                        <div className="card-footer text-center">
                            <button
                                onClick={reset}
                                className="btn btn-sm btn-outline-primary"
                            >
                                Find a different car
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center button-container">
                <div>
                    <Link
                        to={routes.yourDetails.path}
                        className="btn btn-primary"
                    >
                        Yes - that's the one
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default VehicleDetails
