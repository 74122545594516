import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getErrorMessageForType, getFieldErrors } from '../../helpers/formUtils'

FormInput.propTypes = {
    name: PropTypes.string.isRequired,
    inputRef: PropTypes.any.isRequired,
    errors: PropTypes.object,
    label: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string,
}

let inc = 0

/**
 * Form Input Component
 *
 * Built to work with react-hook-form
 *
 * Example usage:
 *
 * <FormInput
 *      type="text"
 *      className="form-control"
 *      label="First name"
 *      name="you.firstName"
 *      errors={form.errors}
 *      inputRef={form.register({ required: true })}
 *      />
 *
 * @param label
 * @param name
 * @param id
 * @param errors
 * @param inputRef
 * @param className
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function FormInput({ label, name, id, errors, inputRef, className, ...props }) {
    const [uid, setUid] = useState(null)
    errors = errors || {}
    useEffect(() => {
        if (!uid) {
            inc++
            setUid(id || name + '_' + inc)
        }
    }, [uid, id, name])
    const fieldErrors = getFieldErrors(name.split('.'), errors)
    return (
        <>
            {label && <label htmlFor={uid}>{label}</label>}
            <input
                id={uid}
                name={name}
                className={className + (fieldErrors ? ' is-invalid' : '')}
                ref={inputRef}
                {...props}
            />
            {fieldErrors && (
                <div className="invalid-feedback">
                    {fieldErrors.message
                        ? fieldErrors.message
                        : getErrorMessageForType(fieldErrors.type)}
                </div>
            )}
        </>
    )
}

export default FormInput
