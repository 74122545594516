import React, { useEffect, useContext } from 'react'
import { useLocation, useHistory, Switch, Route } from 'react-router-dom'
import { StateContext } from './App'
import ThankYou from './pages/ThankYou'
import VehicleLookup from './pages/VehicleLookup'
import VehicleDetails from './pages/VehicleDetails'
import YourDetails from './pages/YourDetails'
import AgreeTerms from './pages/AgreeTerms'

export const routes = {
    vehicleLookup: {
        path: '/',
        component: VehicleLookup,
        progressCheck: () => true, // This should always be true, or it will go loopy.
    },
    vehicleDetails: {
        path: '/vehicle-details',
        component: VehicleDetails,
        progressCheck: (progress) => progress.vehicle,
    },
    yourDetails: {
        path: '/your-details',
        component: YourDetails,
        progressCheck: (progress) => progress.vehicle,
    },
    agreeTerms: {
        path: '/agree-terms',
        component: AgreeTerms,
        progressCheck: (progress) => progress.vehicle && progress.person,
    },
    thankYou: {
        path: '/thank-you',
        component: ThankYou,
        progressCheck: (progress) => progress.submitted,
    },
}

function Routes() {
    const { progress } = useContext(StateContext)
    const location = useLocation()
    const history = useHistory()

    /**
     * This runs when the "location" (route) changes.
     */
    useEffect(() => {
        // On location change, scroll to top
        window.scrollTo(0, 0)
    }, [location])

    /**
     * This is middleware to check that the submission progress is sufficient for the page the user
     * is trying to view
     */
    useEffect(() => {
        const currentPath = location.pathname
        // If the submission is totally complete, we force user to thank you page so they can't re-submit.
        if (
            currentPath !== routes.thankYou.path &&
            routes.thankYou.progressCheck(progress)
        ) {
            history.push(routes.thankYou.path)
            return
        }
        // Otherwise, if it does not match the progressCheck criteria then the user is redirected back to the start.
        const findCurrentRoute = Object.entries(routes).find((o) => {
            return o[1].path === currentPath
        })
        const currentRoute =
            findCurrentRoute &&
            findCurrentRoute.length &&
            routes[findCurrentRoute[0]]
                ? routes[findCurrentRoute[0]]
                : null
        if (currentRoute && !currentRoute.progressCheck(progress)) {
            history.push(routes.vehicleLookup.path)
        }
    }, [location, progress, history])

    return (
        <>
            <Switch>
                <Route
                    path={routes.vehicleDetails.path}
                    component={routes.vehicleDetails.component}
                />
                <Route
                    path={routes.yourDetails.path}
                    component={routes.yourDetails.component}
                />
                <Route
                    path={routes.agreeTerms.path}
                    component={routes.agreeTerms.component}
                />
                <Route
                    path={routes.thankYou.path}
                    component={routes.thankYou.component}
                />
                {/* This is at the bottom because it's the default */}
                <Route
                    path={routes.vehicleLookup.path}
                    component={routes.vehicleLookup.component}
                />
            </Switch>
        </>
    )
}

export default Routes
