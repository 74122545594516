export const initialState = () => ({
    loading: false,
    flash: null,
    progress: {
        vehicle: false,
        person: false,
        terms: false,
        submitted: false,
    },
    data: {
        person: {
            firstName: '',
            lastName: '',
            email: '',
            ...prefillPersonFromGetParams(),
        },
        terms: {
            termsAgreed: null,
            contactAgreed: null,
        },
        vehicle: newVehicle(),
    },
})

export function reducer(state, { type, payload }) {
    const newState = { ...state }
    switch (type) {
        case 'reset':
            return initialState()
        case 'setLoading':
            newState.loading = payload
            return newState
        case 'setPerson':
            newState.data.person = payload
            newState.progress.person = Object.values(payload).reduce(
                (a, v) => !a || !!v.length
            )
            return newState
        case 'resetVehicle':
            newState.data.vehicle = newVehicle()
            newState.progress.vehicle = false
            return newState
        case 'setVehicle':
            newState.data.vehicle = { ...newVehicle(), ...payload }
            newState.progress.vehicle = !!payload.id
            return newState
        case 'setTerms':
            newState.data.terms = payload
            newState.progress.terms = !!payload.termsAgreed
            return newState
        case 'setSubmitted':
            newState.progress.submitted = payload
            return newState
        case 'setFlashMessage':
            newState.flash = payload
            return newState
        default:
            throw new Error()
    }
}

const newVehicle = () => ({
    // These are the fields we currently use on the UI, there may be a few more from API
    id: null,
    colour: '',
    fuelType: '',
    manufacturer: '',
    model: '',
    registration: '',
    transmission: '',
    engineCapacity: '',
})

/**
 * Prefill Person From Get Params
 *
 * If any params are set in url then get values
 *
 * @returns {*}
 */
function prefillPersonFromGetParams() {
    const url = new URL(window.location)
    const params = new URLSearchParams(url.search)
    return {
        firstName: params.get('firstName') || '',
        lastName: params.get('lastName') || '',
        email: params.get('email') || '',
    }
}
