import React, { useState } from 'react'
import { useCookies } from 'react-cookie'

function Cookies() {
    const tld = detectTopLevelDomain()
    const [cookies, setCookie] = useCookies(['cookiesAccepted'])
    const [cookiesAccepted, setCookiesAccepted] = useState(
        !!cookies.cookiesAccepted
    )
    const acceptCookies = () => {
        setCookie(
            'cookiesAccepted',
            new Date().getTime(),
            // Expires far in the future, but not further than 32bit epoch time
            { expires: new Date(2147000000000), domain: '.' + tld }
        )
        setCookiesAccepted(true)
    }
    return (
        <>
            {!cookiesAccepted && (
                <div className="fixed-bottom d-inline-block p-3 text-center">
                    <div className="alert alert-light d-inline-block">
                        <span>
                            By continuing to use this site, you agree to our{' '}
                            <a
                                href={'https://rnwl.co/privacy#cookies'}
                                rel="noreferrer"
                                target="_blank"
                            >
                                cookie policy
                            </a>
                        </span>
                        <button
                            onClick={acceptCookies}
                            className="btn btn-primary btn-sm ml-4"
                        >
                            OK
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}

/**
 * Detect Top Level Domain
 *
 * This looks a bit nasty, but is used to try and work out the top level domain for saving cookie preferences,
 * based on current hostname.
 * It looks for "rnwl" as a section in the current hostname and will use rnwl.x or rnwl.x.y
 * If "rnwl" is not found as a section of the hostname, or if it is further back in the hostname hierarchy, then the
 * current hostname is just used by default.
 *
 * @return {string}
 */
function detectTopLevelDomain() {
    let tld = window.location.hostname
    const hostBits = tld.split('.')
    try {
        // Some matching to see if host is something like "whatever.rnwl.co" or "whatever.rnwl.co.uk"
        if (hostBits[hostBits.length - 2].toString().toLowerCase() === 'rnwl') {
            tld =
                hostBits[hostBits.length - 2] +
                '.' +
                hostBits[hostBits.length - 1]
        } else if (
            hostBits[hostBits.length - 3].toString().toLowerCase() === 'rnwl'
        ) {
            tld =
                hostBits[hostBits.length - 2] +
                '.' +
                hostBits[hostBits.length - 2] +
                '.' +
                hostBits[hostBits.length - 1]
        }
        // else just use host name as tld for cookie preferences.
    } catch (e) {
        // console.log('error: ' + e.message)
    }
    return tld
}

export default Cookies
