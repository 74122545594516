import React, { useReducer } from 'react'
import { BrowserRouter } from 'react-router-dom'
import HeaderSection from './sections/HeaderSection'
import { initialState, reducer } from '../data/state'
import Routes from './Routes'
import Loading from './elements/Loading'
import Cookies from './elements/Cookies'

export const StateContext = React.createContext()
export const DispatchContext = React.createContext()

function App() {
    const [state, dispatch] = useReducer(reducer, initialState())
    return (
        <DispatchContext.Provider value={dispatch}>
            <StateContext.Provider value={state}>
                <div className="App">
                    <BrowserRouter>
                        <HeaderSection />
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-8 col-lg-6 mr-2 ml-2">
                                    {state.loading && <Loading />}
                                    <div
                                        style={{
                                            display: state.loading
                                                ? 'none'
                                                : 'block',
                                        }}
                                    >
                                        <Routes />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Cookies />
                    </BrowserRouter>
                </div>
            </StateContext.Provider>
        </DispatchContext.Provider>
    )
}

export default App
