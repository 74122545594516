import axios from 'axios'
import { fakeApiResponseOnError, successResponse } from './axios-interceptors'

axios.interceptors.response.use(successResponse, fakeApiResponseOnError)

export const authApi = {
    signUp: (data) => axios.post('/sign-up', data, authApiConfig()),
}

export const vehicleApi = {
    lookupVehicle: (registration) =>
        axios.post('/vehicle-lookup', { registration }, vehicleApiConfig()),
}

// -- Helper Methods ---------------------------------------------------------------------------------------------------

const authenticatedConfig = (baseUrl, params) => {
    return {
        params: params || {},
        baseURL: baseUrl,
        withCredentials: true,
    }
}
const authApiConfig = (params) =>
    authenticatedConfig(process.env.REACT_APP_AUTH_SERVICE_API_ENDPOINT, params)
const vehicleApiConfig = (params) =>
    authenticatedConfig(
        process.env.REACT_APP_VEHICLE_SERVICE_API_ENDPOINT,
        params
    )
