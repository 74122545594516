import React, { useContext } from 'react'
import { DispatchContext, StateContext } from '../App'
import { useHistory } from 'react-router-dom'
import { authApi } from '../../helpers/api'
import { routes } from '../Routes'

function AgreeTerms() {
    const dispatch = useContext(DispatchContext)
    const { loading, data } = useContext(StateContext)
    const history = useHistory()

    const signUp = async () => {
        if (loading) {
            return // Already submitted, just ignore
        }
        dispatch({ type: 'setLoading', payload: true })
        const terms = { termsAgreed: new Date() }
        dispatch({
            type: 'setTerms',
            payload: terms,
        })
        try {
            await authApi.signUp({ ...data, ...{ terms } })
            dispatch({ type: 'setSubmitted', payload: true })
            dispatch({ type: 'setLoading', payload: false })
            history.push(routes.thankYou.path)
        } catch (error) {
            // If the API returns an error, then just redirect back to the start.
            // It shouldn't happen, there will only be errors if something has messed up the process, so this is
            // just a way of handling it without crashing.
            dispatch({
                type: 'setFlashMessage',
                payload: {
                    type: 'danger',
                    message:
                        'An error occurred and your account could not be created, please check your details and try again.',
                },
            })
            history.push(routes.vehicleLookup.path)
            dispatch({ type: 'setLoading', payload: false })
        }
    }

    return (
        <div>
            <div className="main-container">
                <div>
                    <div className="row justify-content-center">
                        <div className="col-sm-12">
                            <h3 className="content-title">
                                How we use your information
                            </h3>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-sm-12">
                            <p>
                                The features of your Rnwl account depend on
                                information you give us. We ensure that your
                                personal data is kept secure at all times.
                            </p>
                            <p>
                                Before you continue, please read and accept our:
                            </p>
                            <ul className="terms-list">
                                <li>
                                    <a
                                        href="https://rnwl.co/terms-and-conditions"
                                        rel="noreferrer"
                                        target="_blank"
                                        title="Click to open the Rnwl terms and conditions in a new tab"
                                    >
                                        Terms and conditions
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://rnwl.co/privacy"
                                        rel="noreferrer"
                                        target="_blank"
                                        title="Click to open the Rnwl privacy policy in a new tab"
                                    >
                                        Privacy policy
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center button-container">
                <div className="col-sm-12 text-center">
                    <button onClick={signUp} className="btn btn-primary">
                        Accept and continue
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AgreeTerms
