// Defaults.
export const successRequest = (config) => config
export const errorRequest = (error) => Promise.reject(error)
export const successResponse = (response) => response
export const errorResponse = (error) => Promise.reject(error)

import fakeData from '../data/fake-data.json'

/**
 * Fake API Response On Error
 *
 * If the API response errors, see if it's using whatever.fake-api as the base url.
 * If it is, then look up a fake data response instead
 *
 * @param error
 * @returns {Promise<never>|Promise<{data: [{firstName: string, email: string}]}>}
 */
export const fakeApiResponseOnError = (error) => {
    if (
        error.config &&
        error.config.baseURL &&
        error.config.baseURL.match(/.*\.fake-api\/?$/)
    ) {
        console.log(
            'Fake Response: ' +
                error.config.method.toString().toUpperCase() +
                ' ' +
                error.config.baseURL +
                error.config.url
        )
        // Else
        return Promise.resolve({
            data:
                fakeData[error.config.baseURL][error.config.url][
                    error.config.method
                ],
        })
    }
    return Promise.reject(error)
}
