import React, { useState, useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { getErrorMessageForType, getFieldErrors } from '../../helpers/formUtils'
import { DispatchContext, StateContext } from '../App'
import { routes } from '../Routes'
import { useHistory } from 'react-router-dom'
import { vehicleApi } from '../../helpers/api'

function VehicleLookup() {
    const dispatch = useContext(DispatchContext)
    const { data, flash } = useContext(StateContext)
    const history = useHistory()
    const { register, handleSubmit, formState, setError } = useForm({
        mode: 'onBlur',
        defaultValues: data,
    })
    const [regFieldError, setRegFieldError] = useState(null)
    useEffect(() => {
        setRegFieldError(
            getFieldErrors(['vehicle', 'registration'], formState.errors)
        )
    }, [formState])

    const lookupVehicle = async (formData) => {
        dispatch({ type: 'setFlashMessage', payload: null }) // Clear flash messaging on submit
        dispatch({ type: 'setLoading', payload: true })
        vehicleApi
            .lookupVehicle(formData.vehicle.registration)
            .then((response) => {
                dispatch({
                    type: 'setVehicle',
                    payload: response.data,
                })
                dispatch({ type: 'setLoading', payload: false })
                history.push(routes.vehicleDetails.path)
            })
            .catch((error) => {
                if (error.response && error.response.status === 429) {
                    setError('vehicle.registration', {
                        message:
                            'You have reached the maximum number of vehicle lookup requests. Please try again later.',
                        type: 'api',
                    })
                } else if (
                    error.response &&
                    error.response.data &&
                    error.response.data.id === 'not_recognised'
                ) {
                    setError('vehicle.registration', {
                        message:
                            'Your number plate was not recognised. Please try again.',
                        type: 'api',
                    })
                } else {
                    const message =
                        error.response &&
                        error.response.data &&
                        error.response.data.err
                            ? error.response.data.err
                            : 'An error occurred.'
                    setError('vehicle.registration', {
                        message: message + ' Please try again.',
                        type: 'api',
                    })
                }
                dispatch({ type: 'setLoading', payload: false })
            })
    }
    return (
        <form
            onSubmit={handleSubmit(lookupVehicle)}
            className="vehicle-lookup-form main-container"
        >
            <div className="form-group">
                {flash !== null && (
                    <div className="form-row">
                        <div className="col">
                            <div className={'alert alert-' + flash.type}>
                                {flash.message}
                            </div>
                        </div>
                    </div>
                )}
                <h3 className="content-title">
                    What is your car registration plate?
                </h3>
                <div className="form-row">
                    <div className="col">
                        <input
                            id="vehicle.registration"
                            name="vehicle.registration"
                            placeholder=""
                            type="text"
                            className={
                                'form-control registration ' +
                                (regFieldError ? ' is-invalid' : '')
                            }
                            ref={register({ required: true })}
                        />
                        {!!regFieldError && (
                            <div className="invalid-feedback">
                                {regFieldError.message
                                    ? regFieldError.message
                                    : getErrorMessageForType(
                                          regFieldError.type
                                      )}
                            </div>
                        )}
                    </div>
                    <div className="col">
                        <button
                            type="submit"
                            className="btn btn-primary ml-2 mr-2"
                        >
                            Find my car
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default VehicleLookup
