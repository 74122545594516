export const getErrorMessageForType = (type, input) => {
    switch (type) {
        case 'required':
            return input === 'radio'
                ? 'Please answer the question'
                : 'Please enter a value'
        default:
            return null
    }
}

export const getFieldErrors = (bits, errs) => {
    let fieldErrors = errs
    for (const b of bits) {
        if (!fieldErrors[b]) {
            return null
        }
        fieldErrors = fieldErrors[b]
    }
    return fieldErrors
}
