import React from 'react'

function HeaderSection() {
    return (
        <header className="">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col">
                        <div className="app-header">
                            <div className="wrap">
                                <a
                                    href="https://rnwl.co"
                                    className="header-icon"
                                >
                                    <img
                                        src="/images/rnwl-logo.svg"
                                        alt="back"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default HeaderSection
