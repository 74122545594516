import React from 'react'
import PropTypes from 'prop-types'

Loading.propTypes = {
    className: PropTypes.string,
}

function Loading({ className }) {
    return (
        <div className={'text-center ' + className}>
            <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

export default Loading
