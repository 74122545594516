import React, { useContext } from 'react'

import { StateContext } from '../App'

function ThankYouPage() {
    const { data } = useContext(StateContext)
    return (
        <div>
            <div className="main-container thank-you-page">
                <div>
                    <h3 className="content-title">Check your inbox ✉️</h3>
                    <div
                        className="card aos-init aos-animate"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                    >
                        <div className="card-body">
                            <p className="text-center">
                                <img
                                    src="/images/saturday-morning@2x.png"
                                    alt="Relaxing with coffee"
                                    style={{ maxWidth: '50%' }}
                                />
                            </p>
                            <p>We have sent a magic link to you at:</p>
                            <p className="text-center font-weight-bold text-secondary">
                                {data.person.email}
                            </p>
                            <p>
                                Tap the magic link in the email and we'll take
                                you to your Rnwl account.
                            </p>
                            <p>
                                If you don't see it in your primary inbox, check
                                your promotions or junk folders.
                            </p>
                        </div>
                    </div>
                    <p className="small">
                        Is your email address wrong?{' '}
                        <a href="/" title="Click here to start again">
                            Click here to start again
                        </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ThankYouPage
